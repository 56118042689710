import { graphql, StaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';
import { Box, Flex, Image, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import markdownRenderer from '../components/MarkdownRenderer';
import Section from '../components/Section';
import Triangle from '../components/Triangle';

const CompanyPicture = styled(Image)`
  transition: all 0.25s ease-out;

  &:hover {
    border-radius: 20%;
  }
`;

const Background = () => (
  <div>
    <Triangle color="secondaryLight" height={['80vh', '80vh']} width={['100vw', '100vw']} invertX />

    <Triangle color="background" height={['50vh', '20vh']} width={['50vw', '50vw']} invertX />

    <Triangle color="secondary" height={['25vh', '40vh']} width={['75vw', '60vw']} invertX invertY />

    <Triangle color="backgroundDark" height={['25vh', '20vh']} width={['100vw', '100vw']} invertY />
  </div>
);

const Experience = () => {
  const [getDateString, setGetDateString] = useState();

  // Wait until the page and window are fully loaded
  useEffect(() => {
    if (window.navigator.language) {
      setGetDateString(
        () => (startDate, endDate) =>
          `${new Date(startDate).toLocaleDateString(window.navigator.language || 'en-GB')} - ${
            endDate ? new Date(endDate).toLocaleDateString(window.navigator.language || 'en-GB') : 'Present'
          }`,
      );
    }
  }, []);

  return (
    <Section.Container id="experience" Background={Background}>
      <Section.Header name="Experience" icon="📚" label="books" />
      <StaticQuery
        query={graphql`
          query ExperienceQuery {
            contentfulAbout {
              experience {
                title
                companyName
                image {
                  resize {
                    src
                  }
                }
                startDate
                endDate
                skills
                description {
                  childrenMarkdownRemark {
                    rawMarkdownBody
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const { experience } = data.contentfulAbout;
          return (
            <>
              {experience.map((exp) => {
                const { title, companyName, image, startDate, endDate, skills, description } = exp;

                return (
                  <Flex flexDirection="column" key={companyName}>
                    <Flex py={[3, 4]} flexDirection="row">
                      <Fade right>
                        <Box ml={[1]} style={{ maxWidth: '50px' }}>
                          {image && image?.resize?.src && (
                            <CompanyPicture src={image.resize.src} alt={title} />
                          )}
                        </Box>
                        <Box px={[3]}>
                          <Text fontSize={20} fontWeight="bold">
                            {title}
                          </Text>
                          <Text>{companyName}</Text>
                          <Text color="grey">{getDateString?.(startDate, endDate) ?? ''}</Text>
                        </Box>
                      </Fade>
                    </Flex>
                    <Fade bottom>
                      <Box px={[1, 2, 5]}>
                        <ReactMarkdown
                          source={description.childrenMarkdownRemark[0].rawMarkdownBody}
                          renderers={markdownRenderer}
                        />
                      </Box>

                      <Box px={[1]} py={[4]}>
                        <Text>Skills: {skills.join(' · ')}</Text>
                      </Box>
                    </Fade>
                  </Flex>
                );
              })}
            </>
          );
        }}
      />
    </Section.Container>
  );
};

export default Experience;
